import React from 'react';
import { observer, inject } from 'mobx-react';
import { SizeDimensions } from '../sizeDimensions/sizeDimensions';
import { IVariantStore } from '../../../../types';
import styles from './sizeVariant.scss';
import { SizeValues } from '../sizeValues/sizeValues';
import { FitAccuracyWrapper } from '../fitAccuracy/fitAccuracyWrapper';

export const SizeVariant = inject('variantStore')(
    observer(({ variantStore }: IVariantStore) => {
        return (
            <div data-testid='size_panel_wrapper' className={styles.size_panel_wrapper}>
                <div className={styles.size_content}>
                    {variantStore.variantSelectionCopy.availableSizeTypes && (
                        <div className={styles.size_panel_content}>
                            <SizeDimensions />
                            {!variantStore.mainStore.isMobileShop && < FitAccuracyWrapper />}
                            <SizeValues />
                            <label className={styles.choosen_size_label}>
                                {variantStore.mainStore.localization.selection}:
                                {' ' + variantStore.selectedSize?.size.label}
                            </label>
                        </div>
                    )}
                </div>
            </div>
        );
    })
);
