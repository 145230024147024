import React from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import styles from './basketPanel.scss';
import { IInjectedProps } from '../../../types';
import BasketIcon from '../../icons/basketIcon';
import BasketSuccessIcon from '../../icons/basketSuccessIcon';
import MinusIcon from '../../icons/minusIcon';
import PlusIcon from '../../icons/plusIcon';

export const articleCountMin = 1;
export const articleCountMax = 9999;

const BasketPanel = inject('store')(
    observer(({ store }: IInjectedProps) => {
        const articleCount = store?.orderModuleStore.articleCount;
        const [basketSuccess, setBasketSuccess] = React.useState(false);

        React.useEffect(() => {
            if (window) {
                window.shell.subscribeTo(
                    'ESPP.BasketModal.WillOpen',
                    disableBasketSuccess,
                    'ESPP.OrderModal.BasketPanel.Success'
                );
                window.shell.subscribeTo(
                    'ESPP.BasketModal.Failed',
                    disableBasketSuccess,
                    'ESPP.OrderModal.BasketPanel.Failed'
                );
            }
        }, []);

        if (store && !store.dataStore.contextData.enableFullBasketLayout) {
            React.useEffect(store.orderModuleStore.updateBasketItemCount, [
                store?.fetchedData.orderModule.articleInfo.salesArticleVariantKey,
            ]);
        }

        function disableBasketSuccess() {
            setBasketSuccess(false);
        }

        function handleArticleCountChange(evt) {
            if (evt.target.value) {
                const newValue = Number(evt.target.value);
                if (newValue % 1 === 0 && newValue >= articleCountMin && newValue <= articleCountMax) {
                    store?.orderModuleStore.setArticleCount(newValue);
                }
            } else {
                store?.orderModuleStore.setArticleCount('');
            }
        }

        function handleArticleCountBlur() {
            if (!articleCount && articleCount !== 0) {
                store?.orderModuleStore.setArticleCount(articleCountMin);
            }
        }

        function handleArticleCountMinus() {
            const count = Number(articleCount);
            if (count > articleCountMin) {
                store?.orderModuleStore.setArticleCount(count - 1);
            }
        }

        function handleArticleCountPlus() {
            const count = Number(articleCount);
            if (count < articleCountMax) {
                store?.orderModuleStore.setArticleCount(count + 1);
            }
        }

        function handleBasketOpenClick() {
            window.shell.publishTo('ESPP.BasketModal.Open', store?.orderModuleStore.basketModalData);
            setBasketSuccess(true);
        }

        const minusClasses = [styles.count_buttons, styles.minus_button].join(' ');
        const plusClasses = [styles.count_buttons, styles.plus_button].join(' ');
        const basketButtonClasses = classNames(styles.basket_button, {
            [styles.basket_button_success]: basketSuccess,
        });

        return (
            <div>
                <div className={styles.basket_panel}>
                    <input
                        type='hidden'
                        name='MasterArticleNo'
                        value={store?.fetchedData.orderModule.articleInfo.masterArticleNo}
                        autoComplete='off'
                        autoCorrect='off'
                        autoCapitalize='off'
                        spellCheck='false'
                    />
                    <input
                        type='hidden'
                        name='SalesArticleVariantKey'
                        value={store?.fetchedData.orderModule.articleInfo.salesArticleVariantKey}
                        autoComplete='off'
                        autoCorrect='off'
                        autoCapitalize='off'
                        spellCheck='false'
                    />

                    <span className={styles.basket_counter}>
                        <button className={minusClasses} onClick={handleArticleCountMinus}>
                            <MinusIcon />
                        </button>
                        <span className={styles.basket_counter_input_container}>
                            <span className={styles.basket_counter_input}>
                                <div>
                                    <input
                                        value={articleCount}
                                        type='number'
                                        size={5}
                                        min={articleCountMin}
                                        max={articleCountMax}
                                        onChange={handleArticleCountChange}
                                        onBlur={handleArticleCountBlur}
                                    />
                                </div>
                                <span>{store?.orderModuleStore.articleUnit}</span>
                            </span>
                        </span>
                        <button className={plusClasses} onClick={handleArticleCountPlus}>
                            <PlusIcon />
                        </button>
                    </span>
                    <button data-testid='om-add_to_basket'
                        className={basketButtonClasses} onClick={handleBasketOpenClick}>
                        <>
                            {!basketSuccess && <BasketIcon />}
                            {basketSuccess && <BasketSuccessIcon />}
                            <span className={styles.basket_button_text}>{store?.localization.basketButtonLocale}</span>
                        </>
                    </button>
                </div>
            </div>
        );
    })
);

export default BasketPanel;
