import React, { useEffect, useRef, useState } from 'react';
import { observer, inject } from 'mobx-react';
import styles from './colorVariant.scss';
import classNames from 'classnames';
import { IInjectedProps } from '../../../../types';
import { CustomSwiper } from '../customeSwiper/customeSwiper';

export const ColorVariant = inject('store')(
    observer(({ store }: IInjectedProps) => {
        let selectedColor = store.variantStore.selectedColor;
        const availableColors = store.variantStore.variantSelectionCopy.availableColors;
        const slidesPerColumn =
            store.getIsMobileView || availableColors.length <= store.variantStore.defaultColumnsPerView ? 1 : 2;

        const tmpSelectedColorIndex = availableColors?.findIndex((color) => {
            return color.code === selectedColor?.code;
        });
        const selectedColorIndex = tmpSelectedColorIndex ? tmpSelectedColorIndex : 0;
        const wrapperRef = useRef<HTMLDivElement>(null);
        const [finishedRender, setFinishedRender] = useState(false);
        const [swipingEnabled, setSwipingEnabled] = useState(true);

        useEffect(() => {
            calculateWidth();
            setFinishedRender(true);
        }, []);

        function calculateWidth() {
            if (store.getIsMobileView) {
                if (wrapperRef.current) {
                    if (wrapperRef.current.clientWidth / 50 > availableColors.length) setSwipingEnabled(false);
                    else setSwipingEnabled(true);
                }
            } else {
                if (availableColors.length / slidesPerColumn > store.variantStore.defaultColumnsPerView)
                    setSwipingEnabled(true);
                else setSwipingEnabled(false);
            }
        }

        function handleColorClick(event: React.MouseEvent) {
            event.preventDefault();
            event.stopPropagation();
            const target = event.currentTarget as HTMLElement;
            const colorCode = target.dataset.colorcode;
            if (colorCode) {
                const selectedColorInternal = availableColors?.find(
                    (availableColor) => availableColor.code == parseInt(colorCode)
                );

                if (selectedColorInternal) {
                    selectedColor = { code: selectedColorInternal.code, name: selectedColorInternal.name };
                    store.variantStore.changeSelectedColor(selectedColor);
                }
            }
        }

        return (
            <>
                <div role='color_content_wrapper' className={styles.color_content_wrapper}>
                    <div role='color_content' className={styles.color_content}>
                        <div role='color_icons' className={styles.color_icons} ref={wrapperRef}>
                            <CustomSwiper
                                type='color'
                                selectedElementIndex={selectedColorIndex}
                                slidesPerColumn={slidesPerColumn}
                                columnsPerView={store.variantStore.defaultColumnsPerView}
                                enabled={finishedRender}
                                swipingEnabled={swipingEnabled}
                            >
                                {availableColors?.map((availableColor, index) => (
                                    <a
                                        key={index}
                                        className={classNames(styles.color_border, {
                                            'om-selected': selectedColorIndex === index,
                                        })}
                                        data-colorcode={availableColor.code}
                                        data-testid={'color-' + availableColor.code}
                                        onClick={handleColorClick}
                                        title={
                                            store.dataStore.orderModule.articleInfo.title + ' ' + availableColor.name
                                        }
                                    >
                                        <img
                                            className={styles.color_icon}
                                            src={availableColor.iconPath}
                                            title={availableColor.name}
                                        ></img>
                                    </a>
                                ))}
                            </CustomSwiper>
                        </div>
                        <label className={styles.choosen_color_label}>
                            {store.localization.selection}:{' ' + selectedColor?.name}
                        </label>
                    </div>
                </div>
            </>
        );
    })
);
