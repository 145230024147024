export class ScrollLock {
    public isLocked = false;

    // tslint:disable-next-line: variable-name
    private static _instance: ScrollLock;
    private bodyScrollPosition = 0;

    static get instance() {
        return this._instance || (this._instance = new this());
    }

    private static isIOS(): boolean {
        return (
            document.documentElement.classList.contains('ios') ||
            /(iPad|iPhone|iPod)/g.test(navigator.userAgent) ||
            (navigator.userAgent.includes('Mac') && navigator.maxTouchPoints > 1)
        );
    }

    public lock(): void {
        if (!this.isLocked) {
            const webContainer = document.querySelector('.container');
            const mobileContainer = document.querySelector('.page-container');
            this.bodyScrollPosition = window.scrollY;

            if (mobileContainer || ScrollLock.isIOS()) {
                document.body.setAttribute(
                    'style',
                    'overflow: hidden !important; ' +
                        'overscroll-behavior: none; position: fixed !important; top: -' +
                        this.bodyScrollPosition +
                        'px;'
                );
                this.isLocked = true;
            } else if (webContainer) {
                document.body.style.cssText += 'overflow: hidden !important; position: static !important;';
                this.isLocked = true;
            }
        }
    }

    public unlock(): void {
        if (this.isLocked) {
            const webContainer = document.querySelector('.container');
            const mobileContainer = document.querySelector('.page-container');

            if (mobileContainer || ScrollLock.isIOS()) {
                document.body.removeAttribute('style');
                document.documentElement.removeAttribute('style');
                mobileContainer && mobileContainer.removeAttribute('style');
                window.scrollTo(0, this.bodyScrollPosition);
                this.isLocked = false;
            } else if (webContainer) {
                let cssText = document.body.style.cssText;
                cssText = cssText.replace('overflow: hidden !important;', '');
                cssText = cssText.replace('position: static !important;', '');
                document.body.style.cssText = cssText;
                this.isLocked = false;
            }
        }
    }
}
